<template>
  <b-row>
    <b-col
      cols="12"
      sm="9"
      md="9"
      lg="10"
      xl="10"
    >

      <ProductContainer
        id="productRequestContainer"
        :title="translateReason(reason)"
        :product="product"
        :vendor="vendor"
        :categoryList="categoryList"
        :buyLimitCond="buyLimitCond"
        :tagbyFeeRef="tagbyFeeRef"
        :baseOptions="baseOptions"
        :shippingFreeCond="shippingFreeCond"
        :updateImg="updateImg"
        :hasStartDate="hasStartDate"
        :hasEndDate="hasEndDate"
        :formattedPrice="formattedPrice"
        :formattedSupplyPrice="formattedSupplyPrice"
        :formattedMarketPrice="formattedMarketPrice"
        :formattedShippingFee="formattedShippingFee"
        :formattedJejuShippingFee="formattedJejuShippingFee"
        :formattedMountainShippingFee="formattedMountainShippingFee"
        :formattedShippingFreeCond="formattedShippingFreeCond"
        @input:price="inputPrice"
        @input:shippingFee="inputShippingFee"
        @copyText="copyText"
        @changeVendor="changeVendor"
        @setStartDate="setStartDate"
        @deleteStartDate="deleteStartDate"
        @setEndDate="setEndDate"
        @deleteEndDate="deleteEndDate"
        @setPrice="setPrice"
        @setTagbyFeeRate="setTagbyFeeRate"
        @setShippingFee="setShippingFee"
        @changeHtml="changeHtml"
        @changeShippingPolicy="changeShippingPolicy"
      />

      <b-card
        v-if="product.reason === 'UPDATE' && baseOptions.length === 0"
        title="옵션 변경사항 없음"
      />
      <ProductOptionContainer
        :isAdditional="false"
        :optionType="optionType"
        :options="baseOptions"
        :optionDepthList="baseOptionDepthList"
        :selectedOptionIndices="selectedBaseOptionIndices"
        @create:options="createBaseOptions"
        @add:optionDepth="addBaseOptionDepth"
        @delete:optionDepth="deleteBaseOptionDepth"
        @toggleAllOptions="toggleAllBaseOptions"
        @delete:options="deleteBaseOptions"
        @delete:allOptions="deleteAllBaseOptions"
        @input:depth="inputBaseOptionDepth"
        @changeOptionType="changeOptionType"
      />

      <!-- 추가옵션이 프론트에서 구현된 후에 active -->
      <!-- <ProductOptionContainer
        :isAdditional="true"
        :optionType="'SINGLE'"
        :options="additionalOptions"
        :optionDepthList="additionalOptionDepthList"
        :selectedOptionIndices="selectedAdditionalOptionIndices"
        @create:options="createAdditionalOptions"
        @add:optionDepth="addAdditionalOptionDepth"
        @delete:optionDepth="deleteAdditionalOptionDepth"
        @toggleAllOptions="toggleAllAdditionalOptions"
        @delete:options="deleteAdditionalOptions"
        @delete:allOptions="deleteAllAdditionalOptions"
        @input:depth="inputAdditionalOptionDepth"
      /> -->

      <ProductAbroadDetail
        :isAbroad="isAbroad"
        :abroadNation="abroadNation"
        :abroadAcceptor="abroadAcceptor"
        :abroadContact="abroadContact"
        @toggleIsAbroad="toggleIsAbroad"
        @input:nation="inputNation"
        @input:acceptor="inputAcceptor"
        @input:contact="inputContact"
      />
    </b-col>
    <b-col
      cols="12"
      sm="3"
      md="3"
      lg="2"
      xl="2"
    >
      <b-card v-if="requestState === 'WAIT'">
        <b-button
          variant="primary"
          class="mb-1"
          block
          :disabled="isDisabled.updateButton"
          @click="confirm"
          v-text="'승인'"
        />
        <b-button
          v-b-modal.deny
          :disabled="isDisabled.updateButton"
          variant="primary"
          class="mb-1"
          block
          v-text="'거부'"
        />
        <b-modal
          id="deny"
          ok-title="거부"
          cancel-title="취소"
          centered
          @ok="deny"
        >
          <b-card>
            밴더에게 보낼 피드백
            <b-form-textarea
              id="feedback"
              v-model="feedback"
              class="mt-1"
              rows="4"
            />
          </b-card>
        </b-modal>
      </b-card>
      <b-card v-else>
        <b-button
          variant="secondary"
          class="mb-1"
          block
          disabled
          v-text="'처리완료'"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  defineComponent, onUnmounted, ref,
} from '@vue/composition-api'
import {
  BButton, BFormInput, BFormTextarea,
  BTooltip, BInputGroup, BIcon, BLink, BCard,
  BRow, BCol, BModal, VBModal,
} from 'bootstrap-vue'
import moment from 'moment'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import store from '@/store'
import router from '@/router'
import ProductContainer from '@/views/commerce/components/product/ProductContainer.vue'
import ProductOptionContainer from '@/views/commerce/components/product/ProductOptionContainer.vue'

import productStoreModule from './productStoreModule'
import ProductViewModel from './ProductViewModel'
import ProductAbroadDetail from './components/product/ProductAbroadDetail.vue'

export default defineComponent({
  props: {
    idx: {
      type: Number,
      required: true,
    },
  },

  directives: {
    'b-modal': VBModal,
  },

  components: {
    BFormInput,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BTooltip,
    BInputGroup,
    BLink,
    BIcon,
    BModal,
    ProductContainer,
    ProductOptionContainer,
    ProductAbroadDetail,
  },

  setup(props) {
    const requestState = ref(null)
    const reason = ref('')
    const feedback = ref('')
    const {
      product,
      vendor,
      categoryList,
      isCropModalOpened,
      additionalShippingFeeCond,
      shippingFreeCond,
      buyLimitCond,
      optionType,

      // base options
      baseOptions,
      baseOptionDepthList,
      selectedBaseOptionIndices,
      // additional option
      additionalOptions,
      additionalOptionDepthList,
      selectedAdditionalOptionIndices,

      isDisabled,
      hasStartDate,
      hasEndDate,

      price,
      supplyPrice,
      marketPrice,
      shippingFee,
      jejuShippingFee,
      mountainShippingFee,
      shippingFreeCondGte,

      // computed
      tagbyFeeRef,
      formattedPrice,
      formattedSupplyPrice,
      formattedMarketPrice,
      formattedShippingFee,
      formattedJejuShippingFee,
      formattedMountainShippingFee,
      formattedShippingFreeCond,

      // methods
      uploadImg,
      updateImg,
      pageRefresh,
      copyText,
      changeVendor,

      // base option
      fromFormtoOptions,
      getBaseOptionDepthFromBaseOptions,
      getSingleTypeCategoriesFromOptions,
      fromBaseOptionsToForm,
      inputBaseOptionDepth,
      addBaseOptionDepth,
      deleteBaseOptionDepth,
      deleteBaseOptions,
      toggleAllBaseOptions,
      deleteAllBaseOptions,
      createBaseOptions,

      // additional option
      createAdditionalOptions,
      addAdditionalOptionDepth,
      deleteAdditionalOptionDepth,
      toggleAllAdditionalOptions,
      deleteAdditionalOptions,
      deleteAllAdditionalOptions,
      inputAdditionalOptionDepth,
      changeOptionType,

      setStartDate,
      deleteStartDate,
      setEndDate,
      deleteEndDate,
      inputPrice,
      setPrice,
      setTagbyFeeRate,
      inputShippingFee,
      setShippingFee,
      convertTagbyFee,

      changeHtml,
      changeShippingPolicy,

      // abroad
      isAbroad,
      abroadNation,
      abroadAcceptor,
      abroadContact,
      toggleIsAbroad,
      inputNation,
      inputAcceptor,
      inputContact,
    } = ProductViewModel()

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-product'
    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
      }
    })

    // get detail
    const getRequestDetail = () => store.dispatch('app-product/fetchRequestDetail', { idx: props.idx })
      .then(response => {
        const { data } = response.data
        reason.value = data.reason
        product.value = data.product
        product.value.idx = data.product.product_idx_id
        vendor.value = data.vendor
        requestState.value = data.request_state

        price.value = data.product.price
        supplyPrice.value = data.product.supply_price
        marketPrice.value = data.product.market_price
        shippingFee.value = data.product.shipping_fee
        tagbyFeeRef.tagbyFeeRate = Number((product.value.tagby_fee_rate * 100).toPrecision(3))

        if (product.value.additional_shipping_fee_cond !== '{}') {
          additionalShippingFeeCond.value = JSON.parse(product.value.additional_shipping_fee_cond)
          jejuShippingFee.value = additionalShippingFeeCond.value.jeju
          mountainShippingFee.value = additionalShippingFeeCond.value.island_mountain
        }

        if (product.value.shipping_free_cond !== '{}') {
          shippingFreeCond.value = JSON.parse(product.value.shipping_free_cond)
          shippingFreeCondGte.value = shippingFreeCond.value.gte
        }

        if (product.value.buy_limit_cond !== '{}') {
          buyLimitCond.value = JSON.parse(product.value.buy_limit_cond)
        }
        if (product.value.start_at) {
          hasStartDate.value = true
          product.value.start_at = moment(product.value.start_at)
            .utcOffset(9)
            .format('YYYY-MM-DD HH:mm')
        }
        if (product.value.end_at) {
          hasEndDate.value = true
          product.value.end_at = moment(product.value.end_at)
            .utcOffset(9)
            .format('YYYY-MM-DD HH:mm')
        }

        // option
        optionType.value = data.options.option_type ?? 'COMB'
        if (data.options.base_options?.length) {
          baseOptions.value = fromFormtoOptions(data.options.base_options, optionType.value)
          if (optionType.value === 'SINGLE') {
            baseOptionDepthList.value = getSingleTypeCategoriesFromOptions([...baseOptions.value])
          } else if (optionType.value === 'COMB') {
            baseOptionDepthList.value = getBaseOptionDepthFromBaseOptions([...baseOptions.value])
          }
          selectedBaseOptionIndices.value = Array.from({ length: baseOptions.value.length }, () => false)

          additionalOptions.value = data.options.additional_options
          additionalOptionDepthList.value = getSingleTypeCategoriesFromOptions([...additionalOptions.value])
        }

        // aboard
        isAbroad.value = data.product.is_abroad
        if (data.product.abroad_json) {
          const abraodDetail = JSON.parse(data.product.abroad_json)
          abroadNation.value = abraodDetail.nation
          abroadAcceptor.value = abraodDetail.acceptor
          abroadContact.value = abraodDetail.contact
        }
      })
    getRequestDetail()

    const getCategoryList = () => store.dispatch('app-product/getCategoryList')
      .then(res => {
        categoryList.value = res.data.data
      }).catch(err => {
        console.log(err.data)
      })
    getCategoryList()

    const translateReason = _reason => {
      switch (_reason) {
        case 'REGISTER':
          return '생성 요청'
        case 'UPDATE':
          return '수정 요청'
        case 'DELETE':
          return '삭제 요청'
        default:
          return '알 수 없음'
      }
    }

    const confirmCreate = async () => {
      convertTagbyFee(product, tagbyFeeRef)
      const result = await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/product/confirm-request/`,
        {
          idx: props.idx,
          sell_type: product.value.sell_type,
          tagby_fee: product.value.tagby_fee,
          tagby_fee_rate: product.value.tagby_fee_rate,
        },
      )
      return result
    }

    const confirmUpdate = async () => {
      convertTagbyFee(product, tagbyFeeRef)
      const result = await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/product/confirm-update/`,
        {
          idx: props.idx,
          tagby_fee: product.value.tagby_fee,
          tagby_fee_rate: product.value.tagby_fee_rate,
        },
      )
      return result
    }

    const confirmDelete = async () => {
      const result = await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/product/confirm-request/`,
        { idx: props.idx },
      )
      return result
    }

    const toast = useToast()
    const confirm = async () => {
      isDisabled.value.updateButton = true
      let statement
      try {
        switch (reason.value) {
          case 'REGISTER':
            statement = '생성'
            await confirmCreate()
            break
          case 'UPDATE':
            statement = '수정'
            await confirmUpdate()
            break
          case 'DELETE':
            statement = '삭제'
            await confirmDelete()
            break
          default:
            alert('unknown state')
        }
        toast({
          component: ToastificationContent,
          props: {
            title: `상품 ${statement}요청 승인하였습니다`,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        router.push({ name: 'commerce-product-list', params: { idx: product.value.idx } })
      } catch {
        toast({
          component: ToastificationContent,
          props: {
            title: `상품 ${statement}요청 실패하였습니다`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const deny = async () => {
      let statement
      switch (reason.value) {
        case 'REGISTER':
          statement = '생성'
          break
        case 'UPDATE':
          statement = '수정'
          break
        case 'DELETE':
          statement = '삭제'
          break
        default:
          alert('unknown state')
      }
      try {
        await axios.post(
          `${process.env.VUE_APP_BACKEND_SERVER}/manage/product/deny-request/`,
          { idx: props.idx, feedback: feedback.value },
        )
        toast({
          component: ToastificationContent,
          props: {
            title: `상품 ${statement}요청 거부하였습니다`,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        router.push({ name: 'commerce-product-view', params: { idx: product.value.idx } })
      } catch {
        toast({
          component: ToastificationContent,
          props: {
            title: `상품 ${statement}요청 거부에 실패하였습니다`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    return {
      // ref
      requestState,
      feedback,
      reason,
      product,
      vendor,
      categoryList,
      isCropModalOpened,
      additionalShippingFeeCond,
      shippingFreeCond,
      buyLimitCond,
      optionType,

      // base options
      baseOptions,
      baseOptionDepthList,
      selectedBaseOptionIndices,
      // additional option
      additionalOptions,
      additionalOptionDepthList,
      selectedAdditionalOptionIndices,

      isDisabled,
      hasStartDate,
      hasEndDate,

      // computed
      tagbyFeeRef,
      formattedPrice,
      formattedSupplyPrice,
      formattedMarketPrice,
      formattedShippingFee,
      formattedJejuShippingFee,
      formattedMountainShippingFee,
      formattedShippingFreeCond,

      // methods
      translateReason,
      confirm,
      deny,
      uploadImg,
      updateImg,
      pageRefresh,
      copyText,
      changeVendor,

      // base option
      fromFormtoOptions,
      getBaseOptionDepthFromBaseOptions,
      getSingleTypeCategoriesFromOptions,
      fromBaseOptionsToForm,
      inputBaseOptionDepth,
      addBaseOptionDepth,
      deleteBaseOptionDepth,
      deleteBaseOptions,
      toggleAllBaseOptions,
      deleteAllBaseOptions,
      createBaseOptions,

      // additional option
      createAdditionalOptions,
      addAdditionalOptionDepth,
      deleteAdditionalOptionDepth,
      toggleAllAdditionalOptions,
      deleteAdditionalOptions,
      deleteAllAdditionalOptions,
      inputAdditionalOptionDepth,
      changeOptionType,

      setStartDate,
      deleteStartDate,
      setEndDate,
      deleteEndDate,
      inputPrice,
      setPrice,
      setTagbyFeeRate,
      inputShippingFee,
      setShippingFee,
      changeHtml,
      changeShippingPolicy,

      // abroad
      isAbroad,
      abroadNation,
      abroadAcceptor,
      abroadContact,
      toggleIsAbroad,
      inputNation,
      inputAcceptor,
      inputContact,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

#productRequestContainer #name {
  pointer-events: none;
}

#productRequestContainer #brand_name {
  pointer-events: none;
}

#productRequestContainer #category {
  pointer-events: none;
}

#productRequestContainer #product_no {
  pointer-events: none;
}

#productRequestContainer #vendor {
  pointer-events: none;
}

#productRequestContainer #max_delivery_days {
  pointer-events: none;
}

#productRequestContainer #min_delivery_days {
  pointer-events: none;
}

#productRequestContainer #stock {
  pointer-events: none;
}

#productRequestContainer #start_at {
  pointer-events: none;
}

#productRequestContainer #end_at {
  pointer-events: none;
}

#productRequestContainer #shipping_free_cond {
  pointer-events: none;
}

#productRequestContainer #supply_price {
  pointer-events: none;
}

#productRequestContainer #price {
  pointer-events: none;
}

#productRequestContainer #market_price {
  pointer-events: none;
}

#productRequestContainer #shipping_fee {
  pointer-events: none;
}

#productRequestContainer #jeju {
  pointer-events: none;
}

#productRequestContainer #island_mountain {
  pointer-events: none;
}

#productRequestContainer #shipping_policy {
  pointer-events: none;
}

#productRequestContainer #description {
  pointer-events: none;
}

#productRequestContainer #delivery_content {
  pointer-events: none;
}

#productRequestContainer #refund_content {
  pointer-events: none;
}

#product-option-container {
  pointer-events: none;
}

#state {
  pointer-events: none;
}

</style>
